import PropTypes from 'prop-types';
import React from 'react';
import Header from './header';

export default class Responsive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {}
    };
  };

  static SCROLL_OFFSET = 60;

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
    document.getElementById('wrapper').addEventListener('click', this.handleClose);
  };

  componentWillUnmount() { 
    document.removeEventListener('scroll', this.handleScroll);
    document.getElementById('wrapper').removeEventListener('click', this.handleClose);
  };

  handleClose = e => this.setState({ open: false, openMenuId: null });

  handleSubClose = e => {
    e.preventDefault();
    this.setState({ openMenuId: null });
  };

  handleScroll = e => {
    const { SCROLL_OFFSET } = this.constructor;
    const { style } = this.state;
    const { innerHeight, scrollY } = window;
    const top = Math.max(0, SCROLL_OFFSET - scrollY);
    const offsetHeight = (document.getElementById('wrap') || {}).offsetHeight || 0;
    const height = Math.min(innerHeight, offsetHeight - scrollY) - top;
    if (height !== style.height || top !== style.top) { this.setState({ style: { height, top } }); }
  };

  handleToggle = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.open) { this.handleScroll(); }
    this.setState(({ open }) => ({ open: !open, openMenuId: null }));
  };

  handleUserClick = e => {
    const { checkoutHeader } = this.props;
    e.preventDefault();
    !checkoutHeader && this.setState({ openMenuId: Header.USER_MENU_ID });
  };

  buildLinks = () => {
    const { checkoutHeader, menuDrops, menuLinks, signInPath, user } = this.props;
    const signInLink = user ? [] : [{ props: { href: signInPath }, title: 'Sign In' }];

    return {
      primaryLinks: checkoutHeader ? signInLink : menuDrops,
      subLinks: checkoutHeader ? [] : menuLinks.concat(signInLink)
    };
  };

  renderMenuListItem = ({ id, options, props, title }) => {
    const linkProps = Object.assign({ className: `responsive-link ${ options ? 'has-menu' : '' }`, href: '#' }, props);
    if (options) { linkProps.onClick = e => { e.preventDefault(); this.setState({ openMenuId: id }); }; }

    return <li key={title}><a {...linkProps}>{title}</a></li>;
  };

  renderSubmenus = () => {
    const { menuDrops, menuLinks, user, userLinks } = this.props;
    const { openMenuId } = this.state;
    const { fullName } = user || {};

    return (
      <React.Fragment>
        { user &&
          userLinks && (
            <div className={`responsive-submenu ${ Header.USER_MENU_ID === openMenuId ? 'open' : '' }`}>
              <ul>
                <li><a href='#' onClick={this.handleSubClose}><i className='fa fa-arrow-left' /> {fullName}</a></li>
                { userLinks.map((a, i) => <li key={`user-${i}`}>{a}</li>) }
              </ul>
            </div>
          ) }
        { menuDrops.concat(menuLinks)
                   .filter(({ options }) => !!options)
                   .map(({ id, options, title }) => (
                     <div className={`responsive-submenu ${ id === openMenuId ? 'open' : '' }`} key={id}>
                       <ul>
                         <li><a href='#' onClick={this.handleSubClose}><i className='fa fa-arrow-left' /> {title}</a></li>
                         { options.map(({ title, value }) => <li key={title}><a href={value} className='responsive-link'>{title}</a></li>) }
                       </ul>
                     </div>
                   )) }
      </React.Fragment>
    );
  };

  renderUser() {
    const { checkoutHeader, user, userLinks } = this.props;
    const { firstName, initials, organizationName } = user || {};

    return user && (
      <React.Fragment>
        <a href='#' className='responsive-user text-center' onClick={this.handleUserClick}>
          <div className='avatar-icon'>{ user ? <label>{initials}</label> : <i class='fa fa-user'/> }</div>
          <div>Hi, {firstName}!</div>
          { organizationName && <div className='responsive-user-company'>{organizationName}</div> }
        </a>
        { checkoutHeader && <ul className='responsive-checkout-menu'>{ userLinks.map((a, i) => <li key={`user-${i}`}>{a}</li>) }</ul> }
      </React.Fragment>
    );
  };

  render() {
    const { open, style } = this.state;
    const { primaryLinks, subLinks } = this.buildLinks();

    return (
      <React.Fragment>
        <div className='header-responsive pull-right visible-xs visible-sm visible-md'>
          <a href='#' onClick={this.handleToggle}><span className='icon-bar' /><span className='icon-bar' /><span className='icon-bar' /></a>
        </div>
        <div className={`body-responsive ${ open ? 'open' : '' } visible-xs visible-sm visible-md`} onClick={e => e.stopPropagation()} style={style}>
          {this.renderSubmenus()}
          {this.renderUser()}
          <ul className='responsive-primary-links'>{primaryLinks.map(this.renderMenuListItem)}</ul>
          <ul className='responsive-sublinks'>{subLinks.map(this.renderMenuListItem)}</ul>
        </div>
      </React.Fragment>
    );
  };
};

Responsive.propTypes = {
  checkoutHeader: PropTypes.bool,
  menuDrops: PropTypes.array,
  menuLinks: PropTypes.array,
  signInPath: PropTypes.string.isRequired,
  user: PropTypes.object,
  userLinks: PropTypes.array,
};

Responsive.defaultProps = {
  menuDrops: [],
  menuLinks: [],
};
