import React from 'react';
import ReactDOM from 'react-dom';
import Header from 'components/header/header';
import 'styles/global/header';

document.addEventListener('DOMContentLoaded', () => {
  const header = document.getElementById('header');
  const props = JSON.parse(header.getAttribute('data-props'));

  ReactDOM.render(<Header {...props} />, header);
});
