import PropTypes from 'prop-types';
import React from 'react';
import Responsive from './responsive';
import Search from './search';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static PREMIUM_LOGO_ID = 'premium-logo';
  static USER_MENU_ID = 'user';

  componentDidMount() {
    window.addEventListener('click', e => this.setState({ activeMenuId: null, openMenu: null }));
  };
  componentWillUnmount() { window.removeEventListener('click', e => this.setState({ activeMenuId: null, openMenu: null })); };

  handleMenuClick = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.blur();
    this.setState(({ activeMenuId }) => ({ activeMenuId: activeMenuId === id ? null : id }));
  };

  enterpriseUser = () => this.props.logo.id ===  this.constructor.PREMIUM_LOGO_ID;

  arrangeLogo = (img, { id }) => {
    if (this.enterpriseUser() && img && img.style) {
      const loaded = () => {
        img.style.marginTop = `${(document.getElementsByClassName('marketplace-header-primary')[0].clientHeight - img.clientHeight)/4}px`;
      };
      img.complete ? loaded() : img.onload = loaded;
    }
  };

  linksForUser() {
    const { logoutPath, myEventsPath, myProfilePath, user, signInPath, signUpPath } = this.props;

    return (
      user ?
        [<a href={myEventsPath} key='myEventsLink'>My Events & Invoices</a>,
         <a href={myProfilePath} key='myProfileLink'>My Profile</a>,
         <a href={logoutPath} key='logoutLink'>Sign Out</a>]
      : [<a href={signUpPath} key='myEventsLink'>Sign Up For Free</a>,
         <a href={signInPath} key='myEventsLink'>Log In</a>]
    );
  };

  renderLogo() {
    const { logoLink, logo } = this.props;
    const premiumLogo = this.enterpriseUser();
    return (
      <div className='navbar-brand clearfix' id='logo_container'>
        <a
        {...logoLink}>{<img {...logo} ref={img => this.arrangeLogo(img, logo)} />}
        { premiumLogo && <div className='powered-by-kapow'><p>Powered By Kapow!</p></div> }
        </a>
      </div>
    );
  };

  renderMenuBarItem = ({ id, options, props, title }) => {
    const { activeMenuId } = this.state;
    const linkProps = Object.assign({ className: 'btn menu-bar-item-link', href: '#' }, props);
    if (options) {
      Object.assign(linkProps,
                    { className: `menu-bar-item-link menu-bar-item-drop ${ activeMenuId === id ? 'active' : '' }`,
                      onClick: e => this.handleMenuClick(id, e) });
    }

    return (
      <div className={`menu-bar-item ${ this.enterpriseUser() ? 'enterprise' : ''}`} key={title}>
        <a {...linkProps}>{title}</a>
        { options && <ul>{ options.map(({ title, value }) => <li key={title}><a href={value}>{title}</a></li>) }</ul> }
      </div>
    );
  };

  renderMenuBar() {
    const { checkoutHeader, menuDrops, menuLinks } = this.props;

    return !checkoutHeader && (
      <div className='marketplace-header-menus hidden-xs hidden-sm hidden-md'>
        {menuLinks.map(this.renderMenuBarItem)}
        {menuDrops.map(this.renderMenuBarItem)}
      </div>
    );
  };

  renderResponsive() {
    const { checkoutHeader, menuDrops, menuLinks, signInPath, user } = this.props;

    return <Responsive checkoutHeader={checkoutHeader}
                       menuDrops={menuDrops}
                       menuLinks={menuLinks}
                       signInPath={signInPath}
                       user={user}
                       userLinks={user && this.linksForUser()} />;
  };

  renderSearch() {
    const { checkoutHeader, globalSearchProps } = this.props;
    const { toggleClose } = this.state;

    return !checkoutHeader && <Search {...globalSearchProps} toggleClose={toggleClose}/>;
  };

  renderUser() {
    const { USER_MENU_ID } = this.constructor;
    const { signInPath, user } = this.props;
    const { firstName, lastName, organizationName } = user || {};
    const { activeMenuId } = this.state;
    const initials = (firstName && lastName) ? `${firstName[0]} ${lastName[0]}` : '';
    return (
      <div className='header-user pull-right hidden-xs hidden-sm hidden-md'>
        <a href='#'
           className={`btn header-user-link ${ activeMenuId === USER_MENU_ID ? 'active' : '' } clearfix`}
           onClick={e => this.handleMenuClick(USER_MENU_ID, e)}>
          <div className='header-user-link-text row'>
            <div className='header-user-link-name col-md-10'>
              <div className='avatar-icon'>{ user ? <label>{initials}</label> : <i className='fa fa-user'/> }</div>
              My Account
            </div>
          </div>
        </a>
        <ul>{this.linksForUser().map((a, i) => <li key={`user-link-${i}`}>{a}</li>)}</ul>
      </div>
    );
  };

  render() {
    return (
      <div className='marketplace-header container-fluid' onClick={e => this.setState(({ toggleClose }) => ({ toggleClose: !toggleClose }))}>
        <nav>
          <div className='marketplace-header-primary clearfix row'>
            <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>{this.renderLogo()}</div>
            <div className='col-lg-5 col-md-6 col-sm-7'>{this.renderSearch()}</div>
            <div className='col-lg-4 menu-bar-buttons'>{this.renderMenuBar()}</div>
            <div className='col-lg-1'>{this.renderUser()}</div>
            {this.renderResponsive()}
          </div>
        </nav>
      </div>
    );
  };
};

Header.propTypes = {
  checkoutHeader: PropTypes.bool,
  globalSearchProps: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  logoutPath: PropTypes.string.isRequired,
  logoLink: PropTypes.object,
  menuDrops: PropTypes.array,
  menuLinks: PropTypes.array,
  myEventsPath: PropTypes.string.isRequired,
  myProfilePath: PropTypes.string.isRequired,
  signInPath: PropTypes.string.isRequired,
  user: PropTypes.object,
};

Header.defaultProps = {
  menuDrops: [],
  menuLinks: [],
};
