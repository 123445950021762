import PropTypes from 'prop-types';
import React from 'react';

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationSearch: '',
      search: '',
    };
  };

  static DEBOUNCE_MS = 500;
  static MARKET_COLUMNS = 1;
  static MARKET_COLUMNS_MOBILE = 2;

  componentDidMount() {
    document.getElementById('wrapper').addEventListener('click', this.handleClose);
  };

  componentDidUpdate(prevProps, prevState) { if (prevProps.toggleClose !== this.props.toggleClose) { this.handleClose(); } };

  componentWillUnmount() { document.getElementById('wrapper').removeEventListener('click', this.handleClose); };

  handleClose = e => this.setState({ googlePlaces: null, locationSearch: '', openLocation: false });

  handleCloseResponsive = e => {
    e.preventDefault();
    this.setState({ openResponsive: false }, this.handleClose);
  };

  handleLocationSearchChange = ({ target: { value } }) => {
    const { DEBOUNCE_MS } = this.constructor;

    this.setState({ googlePlaces: null, locationSearch: value });
    clearTimeout(this.googleAutompleteTimeout);
    if (value.trim()) { this.googleAutompleteTimeout = setTimeout(this.googleAutocomplete, DEBOUNCE_MS); }
  };

  handleOpenResponsive = e => {
    e && e.preventDefault();
    this.setState(({ srp }) => ({ openLocation: true, openResponsive: true }));
  };

  handleResponsiveRemoveLocation = e => {
    e.preventDefault();
    this.setState({ openLocation: true, srp: null });
  };

  handleSearch = e => {
    e && e.preventDefault();
    const { search } = this.state;

    this.doSearch(search.trim() ? { term: search } : {});
  };

  handleSearchFocus = e => {
    const { srp } = this.state;
    this.setState({ openLocation: true });
  };

  handleSetSrp = (srp, e) => {
    e && e.preventDefault();
    this.setState({ srp }, () => { this.handleClose(); this.handleSearch(); });
  };

  doSearch = params => window.location = this.searchResultsPageUrl(params);

  // convert a market SRP url like /events/:market or /events/:market/ to the search parameter object { market: :market }
  extractMarketParams = url => {
    const urlSegments = url.replace(/\/$/g, '').split('/');

    return { market: urlSegments[urlSegments.length - 1] };
  };

  googleAutocomplete = () => {
    const { locationSearch } = this.state;
    const { google: { maps: { places: { AutocompleteService } } } } = window;

    if (locationSearch.trim()) {
      this.autocompleter = this.autocompleter || new AutocompleteService();
      this.autocompleter.getPlacePredictions({
        componentRestrictions: { country: 'US' },
        input: locationSearch,
      }, googlePlaces => this.setState({ googlePlaces }));
    }
  };

  googleGeocode = (name, placeId) => {
    const { radiusSearchPath } = this.props;
    const { google: { maps: { Geocoder } } } = window;

    this.geocoder = this.geocoder || new Geocoder();
    this.geocoder.geocode({ placeId }, geocodings => {
      if (!geocodings || !geocodings.length) { return; }

      const { geometry: { location } } = geocodings[0];
      const lat = location.lat();
      const lon = location.lng();
      this.handleSetSrp({ name,
                          params: { lat, lon },
                          url: `${radiusSearchPath}?lat=${lat}&lon=${lon}&query=${encodeURIComponent(name.match(/^[^,]*(,|.)[^,]*/)[0])}` });
    });
  };

  searchResultsPageUrl = params => {
    const { srp: { url } } = this.state;
    const query = Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&');
    const queryString = query ? `${ url.includes('?') ? '&' : '?' }${query}` : '';

    return `${url}${queryString}`;
  };

  renderGooglePlace = (description, matches) => {
    const { length, offset } = (matches && matches.length && matches[0]) || {};
    if (!length) { return description; }

    const endOffset = offset + length;
    const pre = description.slice(0, offset);
    const match = <em>{description.slice(offset, endOffset)}</em>;
    const post = description.slice(endOffset, description.length);

    return <span>{pre}{match}{post}</span>;
  };

  renderGooglePlaces() {
    const { googlePlaces } = this.state;

    return (
      <div className='global-google-places-list loader-container'>
        { googlePlaces ? (
            <ul className='text-left'>
              { googlePlaces.map(({ description,
                                    matched_substrings,
                                    place_id }) => <li key={place_id}>
                                                     <a className='google-place-link'
                                                        href='#'
                                                        onClick={e => { e.preventDefault(); this.googleGeocode(description, place_id); }}>
                                                          {this.renderGooglePlace(description, matched_substrings)}
                                                     </a>
                                                   </li>) }
            </ul>
          ) : <div className='loader' /> }
      </div>
    );
  };

  renderLocationPanel() {
    const { locationSearch } = this.state;

    return (
      <div className='global-location-panel'>
        { locationSearch.trim() ? this.renderGooglePlaces() : this.renderMarketList() }
      </div>
    );
  };

  renderMarketList() {
    const { MARKET_COLUMNS, MARKET_COLUMNS_MOBILE } = this.constructor;
    const marketOptions = this.props.marketOptions.slice(0);

    return (
      <div className='global-market-list'>
        <div className='hidden-xs'>{this.renderMarketOptionsInColumns(MARKET_COLUMNS)}</div>
        <div className='visible-xs'>{this.renderMarketOptionsInColumns(MARKET_COLUMNS_MOBILE, 'xs')}</div>
      </div>
    );
  };

  renderMarketOptionsInColumns = (columnCount, bsClass = 'sm') => {
    const marketOptions = this.props.marketOptions.slice(0);
    const bsCol = Math.floor(12/columnCount);

    return (
      <div className='row'>
        <div className={`col-${bsClass}-${bsCol}`}>
        <ul className='text-left'>
          { marketOptions.map(({title, value}) => <li key={title}>
                                                      <a href={value} onClick={e => this.handleSetSrp({ name: title,
                                                                                                        params: this.extractMarketParams(value),
                                                                                                        url: value }, e)}>{title}</a>
                                                  </li>)}
        </ul>
        </div>
      </div>
    );
  };

  renderResponsivePanel() {
    const { openResponsive } = this.state;

    return (
      <div className={`responsive-search-panel visible-xs ${ openResponsive ? 'open' : '' }`}>
        <div className='responsive-search-panel-header text-right'>
          <a href='#' onClick={this.handleCloseResponsive}>CLOSE</a>
        </div>
          <React.Fragment>
            {this.renderSearchInput()}
            {this.renderLocationPanel()}
          </React.Fragment>
      </div>
    );
  };

  renderSearchInput() {
    const { locationSearch, srp, openLocation } = this.state;

    return (
      <div className='global-input'>
        <input className={`data-hj-whitelist ${openLocation ? 'market-display' : ''}`}
               id='listings-search-input'
               onChange={this.handleLocationSearchChange}
               onFocus={this.handleSearchFocus}
               placeholder='Enter city, address, or place name'
               type='TEXT'
               autoComplete='off'
               value={locationSearch} />
        <i className='c-icon c-icon-search' onClick={this.handleSearch} />
      </div>
    );
  };

  render() {
    const { openLocation } = this.state;
    return (
      <React.Fragment>
        <div className='global-search'>
          <div className={`global-search-bar ${openLocation ? 'open-location-select' : ''} hidden-xs`} onClick={e => e.stopPropagation()}>
            {this.renderSearchInput()}
            { openLocation && this.renderLocationPanel() }
          </div>
        </div>
        <div className='header-responsive-search pull-right visible-xs'>
          <i className='c-icon c-icon-search' onClick={this.handleOpenResponsive} />
        </div>
        {this.renderResponsivePanel()}
      </React.Fragment>
    );
  };
};

Search.propTypes = {
  listingPath: PropTypes.string.isRequired,
  marketOptions: PropTypes.array.isRequired,
  radiusSearchPath: PropTypes.string.isRequired,
};
